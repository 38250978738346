export const prepDataForTransit = (data) => {
  const {
    transitId,
    mapId,
    transitType = '',
    frequency,
    url,
    frontend,
    cms,
    enabled,
    rtTripFeedUrl,
    rtServiceFeedUrl,
    rtVehiclePositionFeedUrl,
    rtGtfsVehicleLinkType,
    enabledRoutes,
    selectedRoutes,
  } = data;
  return {
    ...(transitId && { transit_id: transitId }),
    map_id: mapId,
    transit_type: transitType.toLowerCase(),
    frequency: frequency * 3600,
    url,
    frontend,
    cms,
    enabled,
    rt_trip_feed_url: rtTripFeedUrl,
    rt_service_feed_url: rtServiceFeedUrl,
    rt_vehicle_position_feed_url: rtVehiclePositionFeedUrl,
    rt_gtfs_vehicle_link_type: rtGtfsVehicleLinkType,
    enabled_routes: enabledRoutes,
    selected_routes: selectedRoutes,
  };
};

export const prepDataForAccounts = (data = {}) => {
  const { frequency, transitType = '' } = data;
  return {
    ...data,
    transitType: transitType.toUpperCase(),
    frequency: frequency / 3600,
  };
};
